import axios from "axios";

const timeout = 20 * 1000;

export const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production'?`https://account-api.alishdahal.com.np`: `http://localhost:5000`,
  timeout,
});
axiosInstance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  const token = window.localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.token) {
      window.localStorage.setItem("token", response.data.token);
    }
    return response.data;
  },
  (error) => {
    if (error.response && error.response.data) {
      if (error.response.data.message) {
        const { message } = error.response.data;

        return Promise.reject(new Error(message));
      }
      return Promise.reject(error.response.data);
    }
    return Promise.reject(
      new Error("Some unusual error occured, please try again")
    );
  }
);
