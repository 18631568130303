import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { 
  // deleteSavingsById, 
  savingList } from "../../../api/saving";
import { CustomSearchBox, StyledIcon, StyledTable } from "../../../components/styledComponent";
import AdminWrapper from "../../../layout/AdminWrapper";
import { formatPrice } from "../../../utils";
import { mdiEyeOutline as mdiEye,mdiAccountOutline as mdiAccount,mdiPencilOutline as mdiPencil } from '@mdi/js';



function DataRow(props) {
  return (
    <tr>
      <td>{formatPrice(props.amount) || "-"}</td>
      <td><span style={{padding: '5px', background: props.withdraw? '#ef5350': '#8bc34a', borderRadius: '10px', color: 'white'}}>{props.withdraw ? 'Withdraw' : 'Deposit'}</span></td>

      <td>{props.date || "-"}</td>
      <td>
        <div style={{display: 'flex', flexWrap: 'no-wrap'}}>
        <Link style={{display:"block"}} to={`/admin/savings/${props.user._id}/view`}>
          <Button style={{border: 'none', backgroundColor: 'transparent', padding: '5px 6px'}} variant="warning">
          <StyledIcon path={mdiEye}
        size={0.8}
        $hoverColor="#3CE8AF"/>
          </Button>
        </Link>
        <Link style={{display:"block"}} to={`/admin/savings/${props._id}/edit`}>
          <Button style={{border: 'none', backgroundColor: 'transparent', padding: '5px 6px'}} variant="warning">
          <StyledIcon path={mdiPencil}
        size={0.8}
        $hoverColor="#60A2E6"/>
          </Button>
        </Link>
    
        </div>
      </td>
    </tr>
  );
}

function ViewSaving() {

  const routeParams = useParams();
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState({});
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });
  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search) {
      if (search === "") {
        setParams((prev) => ({ limit: prev.limit, page: prev.page }));
      } else {
        setParams((prev) => ({ ...prev, search }));
      }
    } else {
      setParams((prev) => ({ limit: prev.limit, page: prev.page }));
    }
  }, [search]);

  
  useEffect(() => {
    setLoading(true);
    if("id" in routeParams){
      savingList({...params, userId: routeParams.id}).then((data) => {
        setUsers(data.data);
        setTotal(data.count);
        if(data.count>0){
          setCurrentUser(data.data[0].user);
        }
        setLoading(false);
      });
    }
    
  }, [params, routeParams]);

  
  function generatePaginations() {
    return [...Array(Math.ceil(total / params.limit)).keys()];
  }


  return (
    <AdminWrapper>
      { (
        <div>
          Member: {currentUser.name ?? 'Name'} <br/>
          Net Balance: Rs. {formatPrice(currentUser.balance) ?? '0'}<br/>
          </div>
      )}
      <div className="mb-1" style={{display: "flex", justifyContent: 'flex-end'}}>

        <CustomSearchBox
          type="text"
          placeholder="Search Savings"
          value={search}
          onInput={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <div>
        <StyledTable
          responsive
          className="table table-responsive"
        >
          <thead>
            <tr>
              <th>Amount(Rs.)</th>
              <th>Type</th>
              <th>Added on</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              users.map((item) => (
                <DataRow
                  key={item._id}
                  {...item}
                />
              ))}
            {!loading && users.length === 0 && (
              <tr>
                <td colSpan={5}>No data found</td>
              </tr>
            )}
            {loading && (
              <tr>
                <td colSpan={5}>Loading</td>
              </tr>
            )}
          </tbody>
        </StyledTable>
        <nav>
          <ul className="pagination justify-content-end">
            <li
              onClick={() => {
                if (params.page !== 1) {
                  setParams((prev) => ({ ...prev, page: +params.page - 1 }));
                }
              }}
              className={params.page === 1 ? "page-item disabled" : "page-item"}
            >
              <span className="page-link" href="#" tabindex="-1">
                Previous
              </span>
            </li>
            {generatePaginations().map((item) => (
              <li
                key={`page${item}`}
                className={
                  params.page === item + 1 ? "page-item active" : "page-item"
                }
                onClick={() => {
                  if (params.page !== item + 1) {
                    setParams((prev) => ({ ...prev, page: item + 1 }));
                  }
                }}
              >
                <span className="page-link" href="#">
                  {item + 1}
                </span>
              </li>
            ))}
            <li
              onClick={() => {
                if (params.page !== Math.ceil(total / params.limit)) {
                  setParams((prev) => ({ ...prev, page: +params.page + 1 }));
                }
              }}
              className={
                params.page === Math.ceil(total / params.limit)
                  ? "page-item disabled"
                  : "page-item"
              }
            >
              <span className="page-link" href="#">
                Next
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </AdminWrapper>
  );
}

export default ViewSaving