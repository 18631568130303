import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../api/user";
import UserForm from "../../../components/UserForm";
import AdminWrapper from "../../../layout/AdminWrapper";

export default function CreateUser() {
  const params = useParams();
  const [user, setUser] = useState({
    name: "",
    email: "",
    contactNumber: "",
  });
  useEffect(() => {
    if ("id" in params) {
      getUserById(params.id).then((res) => {
        setUser(res.data);
      });
    }
  }, [params]);
  return (
    <AdminWrapper>
      <UserForm data={user} />
    </AdminWrapper>
  );
}
