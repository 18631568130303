import React from "react";
import { Form } from "react-bootstrap";
import { InputBox } from "./styledComponent";

export default function InputField(props) {
  return (
    <Form.Group>
      <Form.Label
        style={{
          marginBottom: props.sublabel ? 0 : "0.5rem",
        }}
      >
        {props.label}:&nbsp;
      </Form.Label>
      {/* {props.sublabel && (
        <small style={{ clear: "both", display: "flex" }}>
          <span style={{ color: "red" }}>*</span>Note:{props.sublabel}
        </small>
      )} */}
      <InputBox
        placeholder={"Enter your " + props.label.toLowerCase()}
        {...props}
      />
      {/**
       * props = {
       *    value: '',
       *    onInput: ()=>{},
       *    type: '',
       *    label
       * }
       *
       * <input type={props.type} onInput={props.onInput} value={props.value}/>
       *
       * OR
       *
       * <input {...props}/>
       *
       */}
    </Form.Group>
  );
}
