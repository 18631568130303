import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Header from "../components/Header";

const MainWrapper = styled.div`
  height: 100vh;
`;

export default function Wrapper(props) {
  return (
    <MainWrapper>
      <Header />
      <Container>{props.children}</Container>
    </MainWrapper>
  );
}
