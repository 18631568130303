import React from "react";
import Wrapper from "../layout/Wrapper";

export default function Home() {
  return (
    <Wrapper>
      <div>Home</div>
    </Wrapper>
  );
}
