import React from "react";
import { Container } from "react-bootstrap";
import CustomNavbar from "../components/CustomNavbar";
import { App } from "../components/styledComponent";
import Sidebar from "./../components/Sidebar";

export default function AdminWrapper(props) {
  return (
    <App>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <CustomNavbar />
        <Container className="mt-3 ml-2 mr-2">{props.children}</Container>
      </div>
    </App>
  );
}
