import { axiosInstance } from "./axiosInterceptor";

export const loginUser = async (body) => {
  let url = "/api/v1/login";
  const data = await axiosInstance.post(url, body);
  return data;
};

export const createUser = async (body) => {
  let url = "/api/v1/register";
  const data = await axiosInstance.post(url, body);
  return data;
};

export const getUser = async () => {
  let url = "/api/v1/user";
  const data = await axiosInstance.get(url);
  return data;
};

export const getUserById = async (id) => {
  let url = "/api/v1/user/" + id;
  const data = await axiosInstance.get(url);
  return data;
};

export const updateUserById = async (id, body) => {
  let url = "/api/v1/user/" + id;
  const data = await axiosInstance.put(url, body);
  return data;
};

export const deleteUserById = async (id) => {
  let url = "/api/v1/user/" + id;
  const data = await axiosInstance.delete(url);
  return data;
};

export const listUsers = async (params = {}) => {
  let url = "/api/v1/users";
  const data = await axiosInstance.get(url, { params });
  return data;
};
