import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import { Alert, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { listUsers, updateUserById } from "../api/user";
import { PrimaryButton } from "./styledComponent";
import { Typeahead } from "react-bootstrap-typeahead";
import { applyForLoan } from "../api/saving";
import { formatPrice } from "../utils";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
var adbs = require("ad-bs-converter");

export default function LoanForm(props) {
  /**
   * Maintaining Form State
   */
  const { data } = props;
  const [shouldProvideInstead, setShouldProvideInstead] = useState(false)
  const [formData, setFormData] = useState(props.data);
  const [members, setMembers] = useState([]);
  const [singleSelections, setSingleSelections] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  // const [loanTableData, setLoanTableData] = useState([])

  function onFormFieldValueChange(field, val) {
    setFormData(function (previousValue) {
      return { ...previousValue, [field]: val };
    });
  }

  useEffect(() => {
    listUsers({ limit: 200 }).then((data) => setMembers(data.data));
  }, []);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  function onFormSubmit(event) {
    event.preventDefault();
    /**
     *  axios.[HTTP Method](url, options)
     * if method post, patch, put => (url, data, options)
     * (client ==> server )
     * probable there may be response / error
     * axios.[HTTP Method](url, options).then(function (response){
     *  (server (data, headers, status))
     * })
     * .catch(function(err){
     *   ()
     * })
     */
    setError(null);
    let api = null;
    if (formData._id) {
      api = updateUserById(formData._id, formData);
    } else {
      api = applyForLoan(formData);
    }
    api
      .then((response) => {
        setMessage(response.message);
        setError(null);
        setFormData({
          user: null,
          amount: 0,
        });
      })
      .catch((err) => {
        setError(err.message);
        setMessage("");
      });
  }

  const dateDifference = (date1, date2 = new Date()) => {
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  
  const evaluateDays = (prevDate, nextDate=null) => {
    const convertedDate = adbs.bs2ad(
      prevDate
        .split("-")
        .map((item) => +item)
        .join("/")
    );
    let parsedNextDate = null
    if(nextDate){
      parsedNextDate = adbs.bs2ad(
        nextDate
          .split("-")
          .map((item) => +item)
          .join("/")
      );
    }
    return dateDifference(
      new Date(
        `${convertedDate?.year}/${convertedDate?.month}/${convertedDate?.day}`
      ),
      parsedNextDate ?  new Date(
        `${parsedNextDate?.year}/${parsedNextDate?.month}/${parsedNextDate?.day}`
      )  : new Date()
    );
  };
  
  // const generateLoanData = (period=20)=>{
  //   if(isNaN(period)) return;
  //   if(!formData.amount) return;
  //   if(!formData.monthAmount) return;
  //   setLoanTableData([])
  //   const arr = []
  //   let principal = parseFloat(formData.amount)
  //   let i = 0;
  //   while(principal>=0){
  //     const interest = (principal * 16 * evaluateDays(formData.date) /36500).toFixed(2)
  //     arr.push({
  //       id: i+1,
  //       principal: principal.toFixed(2),
  //       interest,
  //     })
  //     if(parseFloat(formData.monthAmount) > interest ){
  //       principal = principal - (parseFloat(formData.monthAmount)  - interest)
  //     }
  //     i = i+1;
  //   }
  //   setLoanTableData(arr)
  // }
  return (
    <Form onSubmit={onFormSubmit}>
      <Row>
        <Col sm={12} md={6} lg={4} xl={4}>
          <Form.Group>
            <Form.Label>Member</Form.Label>
            <Typeahead
              id="basic-typeahead-single"
              labelKey="name"
              onChange={(selected) => {
                setSingleSelections(selected);
                if (selected.length) {
                  onFormFieldValueChange("user", selected[0]._id);
                }
              }}
              options={members}
              autocomplete="off"
              ariaAutocomplete="off"
              // placeholder=""
              selected={singleSelections}
            />
          </Form.Group>

          <InputField
            label="Amount"
            value={formData.amount}
            type="number"
            onInput={(e) => {onFormFieldValueChange("amount", e.target.value);}}
          />
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <NepaliDatePicker
              inputClassName="form-control"
              className=""
              value={formData.date}
              onChange={(e) => onFormFieldValueChange("date", e)}
              options={{ calenderLocale: "en", valueLocale: "en" }}
            />
          </Form.Group>
          <InputField
            label="Montly Payment"
            value={formData.monthAmount}
            type="number"
            onInput={(e) => {onFormFieldValueChange("monthAmount", e.target.value); }}
          />

<InputField
            label="Interest Rate"
            value={formData.interestRate}
            type="number"
            onInput={(e) => {onFormFieldValueChange("interestRate", e.target.value); }}
          />
          <p>
            <span style={{ color: "red" }}>*</span> Note: Interest rate will be
            {formData.interestRate} % p.a. and 2% service charge
          </p>
          
          {singleSelections.length > 0 &&
            singleSelections[0].loans &&
            "remaining" in singleSelections[0].loans && (
             <>
               <p>
                Sorry, {singleSelections[0].name} has not cleared previous loan.
              </p>
              </>
            )}
          {!shouldProvideInstead && singleSelections.length > 0 &&
            singleSelections[0].balance < 1 *1000 && (
              <>
                <p>
                Sorry, {singleSelections[0].name} donot have enough saving to grant loan.
                {!shouldProvideInstead && <span onClick={()=>setShouldProvideInstead(true)}>Provide loan instead</span>}
              </p>
              
              </>
            )}
          {message !== "" && (
            <Alert variant="success">
              {message} <Link to="/admin/loan">View Loans</Link>
            </Alert>
          )}
          {error && <Alert variant="warning">{error}</Alert>}
          {singleSelections.length > 0 &&
            formData.amount > 0 &&
            !isNaN(formData.amount) && (
              <>
                <p style={{ margin: 0, padding: 0 }}>
                  {" Service charge: Rs. "}
                  {formatPrice(+formData.amount * 0.02)}
                </p>
                <p style={{ margin: 0, padding: 0 }}>
                  Total Amount: Rs. {formatPrice(formData.amount)}
                </p>
                {/* <table>
                  <thead>
                    <tr>
                    <th>S.N.</th>
                    <th>Payment Date</th>
                    <th>Principal</th>
                    <th>Interest</th>
                    </tr>
                  </thead>
                  <tbody>
                      {loanTableData.map(item=><tr key={`item${item.id}`}>
                          <td>{item.id}</td>
                          <td>{item.paymentDate}</td>
                          <td>{item.principal}</td>
                          <td>{item.interest}</td>

                      </tr>)}
                  </tbody>
                </table> */}
              </>
            )}
          <PrimaryButton
            disabled={
              (!shouldProvideInstead && singleSelections.length > 0 && singleSelections[0].balance < 1 *1000) ||
              (!shouldProvideInstead  && singleSelections.length > 0 &&
              singleSelections[0].loans &&
              "amount" in singleSelections[0].loans && singleSelections[0].loans.amount > 200000)
            }
            className="p-1 mt-3"
            type="submit"
          >
            Submit
          </PrimaryButton>
        </Col>
        <Col sm={12} md={6} lg={4} xl={4}>
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Card.Title>{singleSelections[0]?.name}</Card.Title>
              <Card.Subtitle className="mt-2">Member Details</Card.Subtitle>
              <Card.Text className="mb-0">
                <span className="text-muted text-bold">Contact Number: </span>
                {singleSelections[0]?.contactNumber || "-"}
              </Card.Text>
              <Card.Text className="mb-0">
                <span className="text-muted text-bold">Balance:</span> Rs.{" "}
                {formatPrice(singleSelections[0]?.balance) || "0"}
              </Card.Text>
              <br />
              {singleSelections.length > 0 &&
                singleSelections[0].loans &&
                "remaining" in singleSelections[0].loans && (
                  <>
                    <Card.Subtitle>Loan Details</Card.Subtitle>
                    <Card.Text className="mb-0">
                      <span className="text-muted text-bold">Loan Taken:</span>{" "}
                      Rs.
                      {formatPrice(singleSelections[0]?.loans?.amount) || "0"}
                    </Card.Text>
                    <Card.Text className="mb-0">
                      <span className="text-muted">Remaining Principal:</span>{" "}
                      Rs.
                      {formatPrice(singleSelections[0]?.loans?.remaining) ||
                        "0"}
                    </Card.Text>
                    <Card.Text className="mb-0">
                      <span className="text-muted">Interest Rate: </span>
                      {singleSelections[0]?.loans?.interestRate || "0"}%
                    </Card.Text>
                    <Card.Text className="mb-0">
                        <span className="text-muted">Interest Payable: </span>
                        {(
                          (+singleSelections[0]?.loans?.remaining *
                            +singleSelections[0]?.loans?.interestRate *
                            evaluateDays(singleSelections[0]?.loanClearances?.date ?? singleSelections[0]?.loans?.date, formData.date || null)) /
                          36500
                        ).toFixed(2) || "0"}
                      </Card.Text>
                    <Card.Text className="mb-0">
                        <span className="text-muted">Previous Interest Remaining: </span>
                        {+singleSelections[0]?.loans?.remainingInterest ? singleSelections[0]?.loans?.remainingInterest.toFixed(2) : "0"}
                      </Card.Text>
                    <Card.Text className="mb-0">
                      <span className="text-muted">Loan Provided Date: </span>
                      {singleSelections[0]?.loans?.date || "0"}
                    </Card.Text>
                   
                  </>
                )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Form>
  );
}
