import { mdiAccountOutline as mdiAccount,mdiDeleteOutline as mdiDelete,mdiEyeOutline as mdiEye,mdiPencilOutline as  mdiPencil, mdiPlus } from "@mdi/js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { deleteUserById, listUsers } from "../../../api/user";
import DeleteModal from "../../../components/DeleteModal";
import { CustomSearchBox, PrimaryButton, StyledIcon, StyledTable } from "../../../components/styledComponent";
import AdminWrapper from "../../../layout/AdminWrapper";
import { formatPrice } from "../../../utils";

function DataRow(props) {
  return (
    <tr>
       <td><StyledIcon color="black" style={{marginBottom: '5px'}}  size={0.8} path={mdiAccount}/> &ensp;{props.name || "Default Name"}</td>
      <td>{props.contactNumber || "-"}</td>
      <td>{props.address ?? "-"}</td>
      <td>{formatPrice(props.balance) || "-"}</td>
      <td>{moment(props.createdAt).lang("np").format("LLL") ?? "-"}</td>
      <td>
      <div style={{display: 'flex', flexWrap: 'no-wrap'}}>
        <Link style={{display:"block"}} to={`/admin/members/${props._id}/view`}>
          <Button style={{border: 'none', backgroundColor: 'transparent', padding: '5px 6px'}} variant="warning">
          <StyledIcon path={mdiEye}
        size={0.8}
        $hoverColor="#3CE8AF"/>
          </Button>
        </Link>
        <Link style={{display:"block"}} to={`/admin/members/${props._id}/edit`}>
          <Button style={{border: 'none', backgroundColor: 'transparent', padding: '5px 6px'}} variant="warning">
          <StyledIcon path={mdiPencil}
        size={0.8}
        $hoverColor="#60A2E6"/>
          </Button>
        </Link>
       
        <Button
          onClick={props.onDeleteClick}
          style={{border: 'none', padding: '5px 6px', backgroundColor: 'transparent'}}
          className="p-1"
        >
     
          <StyledIcon path={mdiDelete}
        size={0.8}
        $hoverColor="#DA1A5D"/>
        </Button>
        </div>
      </td>
    </tr>
  );
}

export default function ListUser() {
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [showDelete, setShowDelete] = React.useState(false);
  const [deletingItem, setDeletingItem] = React.useState({});
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search) {
      if (search === "") {
        setParams((prev) => ({ limit: prev.limit, page: prev.page }));
      } else {
        setParams((prev) => ({ ...prev, search }));
      }
    } else {
      setParams((prev) => ({ limit: prev.limit, page: prev.page }));
    }
  }, [search]);

  useEffect(() => {
    setLoading(true);
    listUsers(params).then((data) => {
      setUsers(data.data);
      setTotal(data.count);
      setLoading(false);
    });
  }, [params]);

  function deleteUser() {
    deleteUserById(deletingItem._id).then(() => {
      setShowDelete(false);
      setUsers((usersData) =>
        usersData.filter((each) => each._id !== deletingItem._id)
      );
    });
  }

  function generatePaginations() {
    return [...Array(Math.ceil(total / params.limit)).keys()];
  }

  function onDeleteButtonClick(item) {
    setDeletingItem(item);
    setShowDelete(true);
  }

  return (
    <AdminWrapper>
          <DeleteModal
          show={showDelete}
          changeDelete={setShowDelete}
          deleteItem={deleteUser}
          deletingItem={deletingItem}
        />
      <div  className="mb-2" style={{display: "flex", justifyContent: 'flex-end'}}>
       
        <CustomSearchBox
          type="text"
          placeholder="Search member"
          value={search}
          onInput={(e) => {
            setSearch(e.target.value);
          }}
        
        />

         <Link className="d-block" style={{marginLeft: '10px'}} to="/admin/members/create">
          <PrimaryButton variant="primary" className="p-1">
            <StyledIcon color="white" size={1} path={mdiPlus}/>Member
          </PrimaryButton>
        </Link>
    
      </div>
      <div>
        <StyledTable
          responsive
          className="table table-responsive"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Contact Number</th>
              <th>Address</th>
              <th>Balance</th>
              <th>Added on</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              users.map((item) => (
                <DataRow
                  onDeleteClick={() => onDeleteButtonClick(item)}
                  key={item._id}
                  {...item}
                />
              ))}
            {!loading && users.length === 0 && (
              <tr>
                <td colSpan={6}>No data found</td>
              </tr>
            )}
            {loading && (
              <tr>
                <td colSpan={6}>Loading</td>
              </tr>
            )}
          </tbody>
        </StyledTable>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
            <li
              onClick={() => {
                if (params.page !== 1) {
                  setParams((prev) => ({ ...prev, page: +params.page - 1 }));
                }
              }}
              className={params.page === 1 ? "page-item disabled" : "page-item"}
            >
              <span className="page-link" href="#" tabindex="-1">
                Previous
              </span>
            </li>
            {generatePaginations().map((item) => (
              <li
                key={`page${item}`}
                className={
                  params.page === item + 1 ? "page-item active" : "page-item"
                }
                onClick={() => {
                  if (params.page !== item + 1) {
                    setParams((prev) => ({ ...prev, page: item + 1 }));
                  }
                }}
              >
                <span className="page-link" href="#">
                  {item + 1}
                </span>
              </li>
            ))}
            <li
              onClick={() => {
                if (params.page !== Math.ceil(total / params.limit)) {
                  setParams((prev) => ({ ...prev, page: +params.page + 1 }));
                }
              }}
              className={
                params.page === Math.ceil(total / params.limit)
                  ? "page-item disabled"
                  : "page-item"
              }
            >
              <span className="page-link" href="#">
                Next
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </AdminWrapper>
  );
}
