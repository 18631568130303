import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function DeleteModal(props) {
  return (
    <>
      <Modal show={props.show} onHide={() => props.changeDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Dialog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete{" "}
            <b>{props.deletingItem.name}</b> ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => props.deleteItem()}>
            Yes
          </Button>
          <Button variant="secondary" onClick={() => props.changeDelete(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
