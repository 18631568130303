import React from "react";
import { Container, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { CusNavBar, NavLink } from "./styledComponent";

const routes = [
  {
    path: "/",
    name: "Home",
  },
  {
    path: "/about",
    name: "About",
  },
  {
    path: "/login",
    name: "Login",
  },
];

export default function Header() {
  const location = useLocation();
  return (
    <Container className="pt-4">
      <CusNavBar className="bg-primary" variant="dark">
        <Link
          style={{ color: "#4c33f4c7", fontWeight: "500" }}
          className="navbar-brand d-block"
          to="/"
        >
          Account
        </Link>
        <Nav className="me-2 align-self-end">
          {routes.map((route) => (
            <NavLink
              key={route.path}
              className={`nav-link ${
                location.pathname === route.path ? "active" : ""
              }`}
              to={route.path}
            >
              {route.name}
            </NavLink>
          ))}
        </Nav>
      </CusNavBar>
    </Container>
  );
}
