import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserById } from "../../../api/user";
import LoanForm from "../../../components/LoanForm";
import AdminWrapper from "../../../layout/AdminWrapper";
const adbs = require('ad-bs-converter')

export default function CreateLoan() {
  const params = useParams();
  

  const convertDate = (date= new Date()) => {
    const convertedDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`
    );
    console.log('converted',convertedDate);
    return `${convertedDate?.en.year}-${convertedDate?.en.month}-${convertedDate?.en.day}`
  }

  const [user, setUser] = useState({
    amount: 0,
    user: null,
    date: convertDate(),
    period: 24,
    interestRate: 16
  });

  useEffect(() => {
    if ("id" in params) {
      getUserById(params.id).then((res) => {
        setUser({...res.data, period: 24});
      });
    }
  }, [params]);
  return (
    <AdminWrapper>
      <LoanForm data={user} />
    </AdminWrapper>
  );
}
