import React from "react";
import Wrapper from "../layout/Wrapper";

export default function PageNotFound() {
  return (
    <Wrapper>
      <div>Sorry, Page not found.</div>
    </Wrapper>
  );
}
