import { axiosInstance } from "./axiosInterceptor";

export const savingEntry = async (body) => {
  let url = "/api/v1/savings/entry";
  const data = await axiosInstance.post(url, body);
  return data;
};

export const savingList = async (params = {}) => {
  let url = "/api/v1/savings/";
  const data = await axiosInstance.get(url, { params });
  return data;
};

export const loanClearanceList = async (id,params = {}) => {
  let url = `/api/v1/loan/${id}/clearance`;
  const data = await axiosInstance.get(url, { params });
  return data;
};

export const deleteSavingsById = async (id) => {
  let url = "/api/v1/savings/" + id;
  const data = await axiosInstance.delete(url);
  return data;
}
export const updateSavingById = async (id,body) => {
  let url = "/api/v1/savings/" + id;
  const data = await axiosInstance.put(url, body);
  return data;
}

export const getSavingById = async (id) => {
  let url = "/api/v1/savings/" + id;
  const data = await axiosInstance.get(url);
  return data;
}

export const applyForLoan = async (body) => {
  let url = "/api/v1/loan/apply";
  const data = await axiosInstance.post(url, body);
  return data;
};

export const loanList = async (params = {}) => {
  let url = "/api/v1/loan/";
  const data = await axiosInstance.get(url, { params });
  return data;
};

export const deleteLoanById = async (id) => {
  let url = "/api/v1/loan/" + id;
  const data = await axiosInstance.delete(url);
  return data;
}

export const deleteLoanClearanceById = async (id) => {
  let url = "/api/v1/loan/clearance/" + id;
  const data = await axiosInstance.delete(url);
  return data;
}