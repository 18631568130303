import {
  mdiDeleteOutline as mdiDelete,
} from "@mdi/js";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  deleteLoanClearanceById,
  loanClearanceList,
} from "../../../api/saving";
import DeleteModal from "../../../components/DeleteModal";
import {
  StyledTable,
  StyledIcon
} from "../../../components/styledComponent";
import AdminWrapper from "../../../layout/AdminWrapper";
import { formatPrice } from "../../../utils";

function DataRow(props) {
  return (
    <tr>
      <td>
        {/* <StyledIcon
          color="black"
          style={{ marginBottom: "5px" }}
          size={0.8}
          path={mdiAccount}
        /> */}
        {/* &ensp;{props.loan.user.name || "Default Name"} */}
        {props.sn}
      </td>
      <td>{formatPrice(props.amountPaid) || "-"}</td>
      <td>{formatPrice(props.finePaid) || "-"}</td>
      <td>{formatPrice(props.paidForLoan) || "-"}</td>
      <td>{formatPrice(props.paidForInterest) || "-"}</td>
      <td>{props.date ?? "-"}</td>
      <td className="d-flex">
  
        <Button
          title={
            props.amount !== props.remaining && "Sorry, loan cannot be deleted."
          }
          onClick={props.onDeleteClick}
          style={{
            border: "none",
            padding: "5px 6px",
            backgroundColor: "transparent",
          }}
          disabled={props.amount !== props.remaining}
        >
          <StyledIcon path={mdiDelete} size={0.8} $hoverColor="#DA1A5D" />
        </Button>
      </td>
    </tr>
  );
}

export default function ViewLoan() {
  const routeParams = useParams();
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [showDelete, setShowDelete] = React.useState(false);
  const [deletingItem, setDeletingItem] = React.useState({});
  const [loan, setLoan] = React.useState({})
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });
  const [total, setTotal] = useState(0);

  // const [search, setSearch] = useState("");

  // useEffect(() => {
  //   if (search) {
  //     if (search === "") {
  //       setParams((prev) => ({ limit: prev.limit, page: prev.page }));
  //     } else {
  //       setParams((prev) => ({ ...prev, search }));
  //     }
  //   } else {
  //     setParams((prev) => ({ limit: prev.limit, page: prev.page }));
  //   }
  // }, [search]);

  useEffect(() => {
    setLoading(true);
    if ("id" in routeParams) {

      loanClearanceList(routeParams.id, params).then((data) => {
          console.log(data);
        setUsers(data.data.loans.map((item,index)=>({...item,sn:index+1})));
        setLoan(data.data.loan)
        setTotal(data.count);
        setLoading(false);
      });
    }
  }, [params, routeParams]);
  function deleteUser() {
    deleteLoanClearanceById(deletingItem._id).then(() => {
      setShowDelete(false);
      setUsers((usersData) =>
        usersData.filter((each) => each._id !== deletingItem._id)
      );
    });
  }

  function generatePaginations() {
    return [...Array(Math.ceil(total / params.limit)).keys()];
  }

  function onDeleteButtonClick(item) {
    setDeletingItem(item);
    setShowDelete(true);
  }

  return (
    <AdminWrapper>
 
      <DeleteModal
        show={showDelete}
        changeDelete={setShowDelete}
        deleteItem={deleteUser}
        deletingItem={deletingItem}
      />
      {/* <div
        className="mb-1"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <CustomSearchBox
          type="text"
          placeholder="Search for Loan"
          value={search}
          onInput={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div> */}
      <div className="me-2">
          <p>Member: {loan?.user?.name}</p>
          <p>Contact Number: {loan?.user?.contactNumber}</p>
          <p>Loan Amount: {formatPrice(loan?.amount)}</p>
          <p>Remaining: {formatPrice(loan?.remaining)|| '-'}</p>
      </div>
      <div>
        <StyledTable responsive className="table table-responsive">
          <thead>
            <tr>
              <th>S.N.</th>
              <th>Amount Paid(Rs)</th>
              <th>Amount Fee(Rs)</th>
              <th>Principal paid(Rs)</th>
              <th>Interest Paid (Rs)</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              users.map((item, sn) => (
                <DataRow
                  onDeleteClick={() => onDeleteButtonClick(item)}
                  key={item._id}
                  sn={sn+1}
                  {...item}
                />
              ))}
            {!loading && users.length === 0 && (
              <tr>
                <td colSpan={5}>No data found</td>
              </tr>
            )}
            {loading && (
              <tr>
                <td colSpan={5}>Loading</td>
              </tr>
            )}
          </tbody>
        </StyledTable>
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-end">
            <li
              onClick={() => {
                if (params.page !== 1) {
                  setParams((prev) => ({ ...prev, page: +params.page - 1 }));
                }
              }}
              className={params.page === 1 ? "page-item disabled" : "page-item"}
            >
              <span className="page-link" href="#" tabindex="-1">
                Previous
              </span>
            </li>
            {generatePaginations().map((item) => (
              <li
                key={`page${item}`}
                className={
                  params.page === item + 1 ? "page-item active" : "page-item"
                }
                onClick={() => {
                  if (params.page !== item + 1) {
                    setParams((prev) => ({ ...prev, page: item + 1 }));
                  }
                }}
              >
                <span className="page-link" href="#">
                  {item + 1}
                </span>
              </li>
            ))}
            <li
              onClick={() => {
                if (params.page !== Math.ceil(total / params.limit)) {
                  setParams((prev) => ({ ...prev, page: +params.page + 1 }));
                }
              }}
              className={
                params.page === Math.ceil(total / params.limit)
                  ? "page-item disabled"
                  : "page-item"
              }
            >
              <span className="page-link" href="#">
                Next
              </span>
            </li>
          </ul>
        </nav>
      </div>
    </AdminWrapper>
  );
}
