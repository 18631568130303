import { Button, Form, FormControl, Navbar, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon from '@mdi/react';
import styled from "styled-components";

// const svgifyURL = (path)=> {
//   return `data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='100' width='100'><path d="${path}" /></svg>`
// }

export const App = styled.div`
  display: flex;
  background-color: #f4f5fc;
  min-height: 100vh;
`;

export const CusNavBar = styled(Navbar)`
  background-color: #f4f5fc !important;
  border-radius: 3px;
  padding-left: 2%;
  padding-right: 2%;
  justify-content: space-between;
`;

export const PageHeader = styled.div`

font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 36px;

color: #7613ad;
`

export const NavLink = styled(Link)`
  color: #4c33f4c7 !important;
  font-size: 18px;
  fontweight: bold !important;
  &.active {
    color: rgba(118, 19, 173, 1) !important;
  }
`;

export const PrimaryButton = styled(Button)`
  color: #f4f5fc;
  background-color: rgba(118, 19, 173,0.8);
  border: 0;
  padding: 8px!important;
  border-radius: 12px;
  &:active {
    border: 0;
    background-color: rgba(118, 19, 173, 0.65);
    box-shadow: none;
    outline: none !important;
  }
  &:focus {
    outline: none !important;
    border: none;
    background-color: rgba(118, 19, 173,1);
    box-shadow: none;
  }
  &:focus-visible{
    outline: none;
    border: none;
    box-shadow: none;
  }
  &:hover {
    background-color: rgba(118, 19, 173,1);
  }
`;

export const CFormControl = styled(FormControl)`
  height: 34px;
  &:active {
    border: 0;
    box-shadow: none;
    outline: none !important;
  }
  &:focus {
    outline: none !important;
    box-shadow: none;
  }
`;


export const StyledTable = styled(Table)`
  overflow-x: scroll;
  background-color: #ffffff !important;
  color: #6b7280;
  font-size: .875rem;
    line-height: 1.25rem;
  border: none;
  tr {
    border: none;
  }
  thead tr {
    background: #f9fafb;
    color: #868b90;
    text-transform: uppercase;
    border: none;
    border-radius: 10px;
    letter-spacing: .05em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: .75rem;
    line-height: 1rem;
    height: 42px;
  }
  tr{
    border-bottom: 1px solid #e8eaed;
  }
  tbody tr:hover{
    background-color: #f9fafb;
  }
  thead tr th:first-child, thead tr th:last-child {
    border: none;
    padding-left: 15px;
  }
  thead tr th{
    border: none;
  }
  tbody {
    border: none !important;
  }
  tbody tr td {
    border: none;
  }
  tr td:first-child{
    font-weight: 600;
    color: #000000;
    padding-left: 15px;
  }
  th,td {
    clear: both;
    overflow: hidden;
    white-space: nowrap;
    padding: 8px;
    vertical-align: middle;
  }
  th:not(:first-child), td:not(:first-child){
    text-align: center;
  }
  th:last-child{
    text-align: left;
  }
`

export const CustomSearchBox = styled.input`
  border: none;
  text-indent: 10px;
  min-width: 400px;
  background: #ffffff;
  padding: 8px 0px;
  border-radius: 12px;
  &:active {
    border: none
  }
  &:focus-visible{
    outline: 2px solid #25548891;
  }

`

export const InputBox = styled(Form.Control)`
  border: none;
  text-indent: 10px;
  min-width: 400px;
  background: #ffffff;
  padding: 8px 0px;
  border-radius: 12px;
  outline: none;
  &:active {
    outline: none;
    border: none;
  }
  &:focus{
    outline: none;
    border: none;
    box-shadow:none;
  }
  &:focus-visible{
    outline: 2px solid #25548891;
  }

`


export const StyledIcon = styled(Icon)`
  color:#00000082;
  &:hover{
    color: ${props=>props?.$hoverColor ?? 'black'}
  }
`