import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { createUser, updateUserById } from "../api/user";
import { PrimaryButton, StyledIcon } from "./styledComponent";
import { mdiArrowLeft } from "@mdi/js";

export default function UserForm(props) {
  /**
   * Maintaining Form State
   */
  const { data } = props;
  const [formData, setFormData] = useState(props.data);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  function onFormFieldValueChange(field, val) {
    setFormData(function (previousValue) {
      return { ...previousValue, [field]: val };
    });
  }

  useEffect(() => {
    setFormData(data);
  }, [data]);

  function onFormSubmit(event) {
    event.preventDefault();
    /**
     *  axios.[HTTP Method](url, options)
     * if method post, patch, put => (url, data, options)
     * (client ==> server )
     * probable there may be response / error
     * axios.[HTTP Method](url, options).then(function (response){
     *  (server (data, headers, status))
     * })
     * .catch(function(err){
     *   ()
     * })
     */
    setError(null);
    let api = null;
    if (formData._id) {
      api = updateUserById(formData._id, formData);
    } else {
      api = createUser(formData);
    }
    api
      .then((response) => {
        setMessage(response.message);
      })
      .catch((err) => {
        setError(err.message);
      });
  }
  return (
    <>
      <div
        className="mb-1"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Link style={{ display: "block" }} to={`/admin/members/`}>
          <Button
            style={{
              border: "none",
              backgroundColor: "transparent",
              padding: "5px 6px",
            }}
            variant="warning"
          >
            <StyledIcon path={mdiArrowLeft} size={1} $hoverColor="#60A2E6" />
          </Button>
        </Link>
      </div>
      <Row>
        <Col sm={12} md={6} lg={4} xl={4}>
          <Form onSubmit={onFormSubmit}>
            <InputField
              label="Name"
              value={formData.name}
              type="text"
              onInput={(e) => onFormFieldValueChange("name", e.target.value)}
              autoFocus
            />
            <InputField
              label="Email"
              value={formData.email}
              type="text"
              onInput={(e) => onFormFieldValueChange("email", e.target.value)}
            />
            <InputField
              label="Contact Number"
              value={formData.contactNumber}
              type="number"
              onInput={(e) =>
                onFormFieldValueChange("contactNumber", e.target.value)
              }
            />
            <InputField
              label="Address"
              value={formData.address}
              type="text"
              onInput={(e) => onFormFieldValueChange("address", e.target.value)}
            />
            {message !== "" && (
           
                  <Alert className="mt-3" variant="success">
                    {message} <Link to="/admin/members">View Members</Link>
                  </Alert>
            )}
            {error && <Alert className="mt-3" variant="warning">{error}</Alert>}
            <PrimaryButton className="p-1 mt-3" type="submit">
              Submit
            </PrimaryButton>
          </Form>
        </Col>
      </Row>
    </>
  );
}
