//import useState hook to create menu collapse state
import React, { useState } from "react";

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";

//import icons from react icons
import {
  FaStar,
  FaUserFriends,
  FaClock,
  FaMoneyBillWave,
} from "react-icons/fa";
import { FiHome, FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi";

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "../styles/sidebar.css";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false);

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    setMenuCollapse((prev) => !prev);
  };
  const location = useLocation();

  return (
    <>
      <div id="header">
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className="logotext">
              {/* small and big change using menucollapse state */}
              <p>
                {menuCollapse ? "SRS" : "SRS Finance"}
                <span onClick={menuIconClick}>
                  {" "}
                  {menuCollapse ? (
                    <FiArrowRightCircle />
                  ) : (
                    <FiArrowLeftCircle />
                  )}
                </span>
              </p>
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              {[
                {
                  to: "/admin",
                  icon: <FiHome />,
                  label: "Dashboard",
                },
                {
                  to: "/admin/members",
                  icon: <FaUserFriends />,
                  label: "Members",
                },
                {
                  to: "/admin/savings",
                  icon: <FaClock />,
                  label: "Savings",
                },
                {
                  to: "/admin/loan",
                  icon: <FaStar />,
                  label: "Loan",
                },
                {
                  to: "/admin/savings/entry",
                  icon: <FaMoneyBillWave />,
                  label: "Deposit Fund",
                },
                {
                  to: "/admin/savings/withdraw",
                  icon: <FaMoneyBillWave />,
                  label: "Withdraw Fund",
                },
                {
                  to: "/admin/loan/provide",
                  icon: <FaStar />,
                  label: "Provide Loan",
                },
              ].map((menu) => (
                <MenuItem
                  key={menu.to}
                  active={location.pathname === menu.to}
                  icon={menu.icon}
                >
                  <Link style={{ color: "inherit" }} key={menu.to} to={menu.to}>
                    {!menuCollapse && menu.label}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
    </>
  );
};

export default Sidebar;
