import React, { useState } from "react";
import { FormGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { loginUser, getUser } from "../api/user";
import { CFormControl, PrimaryButton } from "../components/styledComponent";
import Wrapper from "../layout/Wrapper";

export default function Login() {
  const navigate = useNavigate();
  const [message, setMessage] = useState('')
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  return (
    <Wrapper>
      <div
        className="m-auto mt-5"
        style={{
          minWidth: "250px",
          padding: "10px",
          width: "30%",
          backgroundColor: "#f4f5fc",
          borderRadius: "5px",
        }}
      >
        <div
          style={{ color: "#4c33f4c7", fontWeight: "500", fontSize: "22px" }}
          className="d-block"
        >
          Login
        </div>
        <hr
          className="mb-2"
          style={{
            marginTop: 5,
            height: "3px",
            backgroundColor: "rgb(86 66 217 / 100%)",
          }}
        />
        <form
          onSubmit={(e) => {
            setMessage('');
            e.preventDefault();
            loginUser(data).then((d) => {
              getUser().then((user) => {
                localStorage.setItem("user", JSON.stringify(user));
                navigate("/admin");
              });
            }).catch(err=>{
              if(err.message){
                setMessage(err.message)
              }
            });
          }}
        >
          <FormGroup>
            <label>Email</label>
            <CFormControl
              type="text"
              value={data.email}
              onInput={(e) => setData((d) => ({ ...d, email: e.target.value }))}
            />
          </FormGroup>
          <FormGroup>
            <label>Password</label>
            <CFormControl
              type="password"
              value={data.password}
              onInput={(e) =>
                setData((d) => ({ ...d, password: e.target.value }))
              }
            />
          </FormGroup>
          {!!message && <small style={{color: 'red'}}>{message}</small>}
          <PrimaryButton
            type="submit"
            style={{ width: "100%" }}
            className="mt-2"
          >
            Login
          </PrimaryButton>
        </form>
      </div>
    </Wrapper>
  );
}
