import React from "react";
import Wrapper from "../layout/Wrapper";

export default function About() {
  return (
    <Wrapper>
      <h2>About</h2>
    </Wrapper>
  );
}
