import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import About from "../pages/About";
import Dashboard from "../pages/admin/Dashboard";
import CreateLoan from "../pages/admin/loan/CreateLoan";
import ListLoan from "../pages/admin/loan/ListLoan";
import ViewLoan from "../pages/admin/loan/ViewLoan";
import CreateSaving from "../pages/admin/savings/CreateSaving";
import ViewSavings from "../pages/admin/savings/ViewSaving";
import ListSavings from "../pages/admin/savings/ListSavings";
import CreateUser from "../pages/admin/users/CreateUser";
import ListUser from "../pages/admin/users/ListUser";
import ViewUser from "../pages/admin/users/ViewUser";
import Home from "../pages/Home";
import Login from "../pages/Login";
import PageNotFound from "../pages/PageNotFound";
import CreateWithdraw from "../pages/admin/savings/CreateWithdraw";

export default function Router() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    console.log(location.pathname);
    const token = window.localStorage.getItem("token");

    if (!token && location.pathname.includes("admin")) {
      navigate("/");
    }
  }, [location?.pathname, navigate]);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/about" element={<About />} />
      <Route path="/admin" element={<Dashboard />} />
      <Route path="/admin/loan" element={<ListLoan />} />
      <Route path="/admin/loan/provide" element={<CreateLoan />} />
      <Route path="/admin/loan/:id/edit" element={<CreateLoan />} />
      <Route path="/admin/loan/:id/view" element={<ViewLoan />} />
      <Route path="/admin/savings" element={<ListSavings />} />
      <Route path="/admin/savings/entry" element={<CreateSaving />} />
      <Route path="/admin/savings/withdraw" element={<CreateWithdraw />} />
      <Route path="/admin/savings/:id/edit" element={<CreateSaving />} />
      <Route path="/admin/savings/:id/view" element={<ViewSavings />} />
      <Route path="/admin/members" element={<ListUser />} />
      <Route path="/admin/members/create" element={<CreateUser />} />
      <Route path="/admin/members/:id/edit" element={<CreateUser />} />
      <Route path="/admin/members/:id/view" element={<ViewUser />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
