import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { StyledIcon } from '../../../components/styledComponent'
import { Button } from "react-bootstrap";

import AdminWrapper from '../../../layout/AdminWrapper'
import { mdiAccount, mdiArrowLeft } from '@mdi/js';
import { getUserById } from '../../../api/user';

function ViewUser() {
    const params = useParams();
  const [user, setUser] = useState({
    name: "",
    email: "",
    contactNumber: "",
  });
  useEffect(() => {
    if ("id" in params) {
      getUserById(params.id).then((res) => {
        setUser(res.data);
      });
    }
  }, [params]);
  return (
      <AdminWrapper>
           <div
        className="mb-1"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
         
          <Button
            style={{
              border: "none",
              backgroundColor: "transparent",
              padding: "5px 6px",
            }}
            variant="warning"
          >
            <StyledIcon path={mdiAccount} size={1} $hoverColor="#60A2E6" />{user.name}
          </Button>
        <Link style={{ display: "block" }} to={`/admin/members/`}>
          <Button
            style={{
              border: "none",
              backgroundColor: "transparent",
              padding: "5px 6px",
            }}
            variant="warning"
          >
            <StyledIcon path={mdiArrowLeft} size={1} $hoverColor="#60A2E6" />
          </Button>
        </Link>
      </div>
      </AdminWrapper>
  )
}

export default ViewUser