import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSavingById } from "../../../api/saving";
import WithdrawForm from "../../../components/WithdrawForm";
import AdminWrapper from "../../../layout/AdminWrapper";
const adbs = require("ad-bs-converter");

export default function CreateWithdraw() {
  const params = useParams();
  const convertDate = (date = new Date()) => {
    const convertedDate = adbs.ad2bs(
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
    );
    console.log("converted", convertedDate);
    return `${convertedDate?.en.year}-${convertedDate?.en.month}-${convertedDate?.en.day}`;
  };
  const [user, setUser] = useState({
    user: null,
    amount: 0,
    payAmount: 0,
    fee: 0,
    date: convertDate(),
  });
  useEffect(() => {
    if ("id" in params) {
      getSavingById(params.id).then((res) => {
        setUser(res.data);
      });
    }
  }, [params]);
  return (
    <AdminWrapper>
      <WithdrawForm data={user} />
    </AdminWrapper>
  );
}
