import React from "react";
import AdminWrapper from "../../layout/AdminWrapper";

export default function Dashboard() {
  return (
    <AdminWrapper>
      <div> Dashboard</div>
    </AdminWrapper>
  );
}
