import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { listUsers } from "../api/user";
import { PrimaryButton, StyledIcon } from "./styledComponent";
import { Typeahead } from "react-bootstrap-typeahead";
import { savingEntry, updateSavingById } from "../api/saving";
import { formatPrice } from "../utils";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import { mdiArrowLeft } from "@mdi/js";
var adbs = require("ad-bs-converter");

export default function SavingForm(props) {
  /**
   * Maintaining Form State
   */
  const { data } = props;
  const [isMemberDisabled, setIsMemberDisabled] = useState(false);
  const [formData, setFormData] = useState(props.data);
  const [members, setMembers] = useState([]);
  const [singleSelections, setSingleSelections] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  function onFormFieldValueChange(field, val) {
    setFormData(function (previousValue) {
      return { ...previousValue, [field]: val };
    });
  }

  useEffect(() => {
    listUsers({ limit: 200 }).then((res) => {
      const mem = res.data.map((item) => ({
        ...item,
        name: `${item.name} (${item.contactNumber || "-"})`,
      }));
      setMembers(mem);
    });
  }, []);

  useEffect(() => {
    setFormData(data);
    if (data.user && typeof data.user === "object") {
      setSingleSelections(members.filter((each) => each._id === data.user._id));
      setIsMemberDisabled(true);
    }
  }, [data, members]);

  function onFormSubmit(event) {
    event.preventDefault();
    /**
     *  axios.[HTTP Method](url, options)
     * if method post, patch, put => (url, data, options)
     * (client ==> server )
     * probable there may be response / error
     * axios.[HTTP Method](url, options).then(function (response){
     *  (server (data, headers, status))
     * })
     * .catch(function(err){
     *   ()
     * })
     */
    setError(null);
    let api = null;
    if (formData._id) {
      api = updateSavingById(formData._id, formData);
    } else {
      api = savingEntry(formData);
    }
    api
      .then((response) => {
        setMessage(response.message);
        setError(null);
        setFormData((prev) => ({
          amount: 0,
          user: prev.user,
        }));
        navigate("/admin/savings");
      })
      .catch((err) => {
        setError(err.message);
        setMessage("");
      });
  }

  const dateDifference = (date1, date2 = new Date()) => {
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };
  
  const evaluateDays = (prevDate, nextDate=null) => {
    const convertedDate = adbs.bs2ad(
      prevDate
        .split("-")
        .map((item) => +item)
        .join("/")
    );
    let parsedNextDate = null
    if(nextDate){
      parsedNextDate = adbs.bs2ad(
        nextDate
          .split("-")
          .map((item) => +item)
          .join("/")
      );
    }
    return dateDifference(
      new Date(
        `${convertedDate?.year}/${convertedDate?.month}/${convertedDate?.day}`
      ),
      parsedNextDate ?  new Date(
        `${parsedNextDate?.year}/${parsedNextDate?.month}/${parsedNextDate?.day}`
      )  : new Date()
    );
  };
  
  return (
    <>
      <div
        className="mb-1"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Link style={{ display: "block" }} to={`/admin/savings/`}>
          <Button
            style={{
              border: "none",
              backgroundColor: "transparent",
              padding: "5px 6px",
            }}
            variant="warning"
          >
            <StyledIcon path={mdiArrowLeft} size={1} $hoverColor="#60A2E6" />
          </Button>
        </Link>
      </div>
      <Form onSubmit={onFormSubmit}>
        <Row>
          <Col sm={12} md={6} lg={4} xl={4}>
            <Form.Group>
              <Form.Label>Member</Form.Label>
              <Typeahead
                id="basic-typeahead-single"
                disabled={isMemberDisabled}
                labelKey="name"
                onChange={(selected) => {
                  console.log(selected);
                  setSingleSelections(selected);
                  if (selected.length) {
                    onFormFieldValueChange("user", selected[0]._id);
                  }
                }}
                options={members}
                autocomplete="off"
                ariaAutocomplete="off"
                // placeholder=""
                selected={singleSelections}
              />
            </Form.Group>

            <InputField
              label="Amount for saving (Rs.)"
              value={formData.amount}
              type="number"
              onInput={(e) => onFormFieldValueChange("amount", e.target.value)}
            />
            {singleSelections.length > 0 &&
              singleSelections[0].loans &&
              "remaining" in singleSelections[0].loans && (
                <InputField
                  label={`Pay for loan (Rs.)`}
                  sublabel={` Remaining amount Rs. ${singleSelections[0].loans.remaining} of Previous Loan`}
                  value={formData.payAmount}
                  type="number"
                  onInput={(e) =>
                    onFormFieldValueChange("payAmount", e.target.value)
                  }
                />
              )}

            {singleSelections.length > 0 &&
              singleSelections[0].loans &&
              "remaining" in singleSelections[0].loans && (
                <InputField
                  label={`Fee`}
                  value={formData.fee}
                  type="number"
                  onInput={(e) => onFormFieldValueChange("fee", e.target.value)}
                />
              )}
            <Form.Group>
              <Form.Label>Date</Form.Label>
              <NepaliDatePicker
                inputClassName="form-control"
                className=""
                value={formData.date}
                onChange={(e) => onFormFieldValueChange("date", e)}
                options={{ calenderLocale: "en", valueLocale: "en" }}
              />
            </Form.Group>

            <InputField
              label="Remarks"
              value={formData.remarks}
              type="text"
              onInput={(e) => onFormFieldValueChange("remarks", e.target.value)}
            />
            {message !== "" && (
              <Alert variant="success">
                {message} <Link to="/admin/savings">View Savings</Link>
              </Alert>
            )}
            {error && <Alert variant="warning">{error}</Alert>}
            <PrimaryButton className="p-1 mt-3" type="submit">
              Submit
            </PrimaryButton>
          </Col>
          <Col sm={12} md={6} lg={4} xl={4}>
            <Card style={{ width: "100%" }}>
              <Card.Body>
                <Card.Title>{singleSelections[0]?.name}</Card.Title>
                <Card.Subtitle className="mt-2">Member Details</Card.Subtitle>
                <Card.Text className="mb-0">
                  <span className="text-muted text-bold">Contact Number: </span>
                  {singleSelections[0]?.contactNumber || "-"}
                </Card.Text>
                <Card.Text className="mb-0">
                  <span className="text-muted text-bold">Balance:</span> Rs.{" "}
                  {formatPrice(singleSelections[0]?.balance) || "0"}
                </Card.Text>
                <br />
                {singleSelections.length > 0 &&
                  singleSelections[0].loans &&
                  "remaining" in singleSelections[0].loans && (
                    <>
                      <Card.Subtitle>Loan Details</Card.Subtitle>
                      <Card.Text className="mb-0">
                        <span className="text-muted text-bold">
                          Loan Taken:
                        </span>{" "}
                        Rs.
                        {formatPrice(singleSelections[0]?.loans?.amount) || "0"}
                      </Card.Text>
                      <Card.Text className="mb-0">
                        <span className="text-muted">Remaining Principal:</span>{" "}
                        Rs.
                        {formatPrice(singleSelections[0]?.loans?.remaining) ||
                          "0"}
                      </Card.Text>
                      <Card.Text className="mb-0">
                        <span className="text-muted">Interest Rate: </span>
                        {singleSelections[0]?.loans?.interestRate || "0"}%
                      </Card.Text>
                      <Card.Text className="mb-0">
                        <span className="text-muted">Interest Payable: </span>
                        {(
                          (+singleSelections[0]?.loans?.remaining *
                            +singleSelections[0]?.loans?.interestRate *
                            evaluateDays(singleSelections[0]?.loanClearances?.date ?? singleSelections[0]?.loans?.date, formData.date || null)) /
                          36500
                        ).toFixed(2) || "0"}
                      </Card.Text>
                      <Card.Text className="mb-0">
                        <span className="text-muted">Previous Interest Remaining: </span>
                        {+singleSelections[0]?.loans?.remainingInterest ? singleSelections[0]?.loans?.remainingInterest.toFixed(2) : "0"}

                      </Card.Text>
                      <Card.Text className="mb-0">
                        <span className="text-muted">Loan Provided Date: </span>
                        {singleSelections[0]?.loans?.date || "0"}
                      </Card.Text>
                      <Card.Text className="mb-0">
                      <span className="text-muted">Last Paid on: </span>
                      {  singleSelections[0]?.loanClearances?.date  || "-"}
                    </Card.Text>
                    <Card.Text className="mb-0">
                      <span className="text-muted">Number of days: </span>
                      { evaluateDays(singleSelections[0]?.loanClearances?.date ?? singleSelections[0]?.loans?.date, formData.date || null)  || "-"}
                    </Card.Text>
                    </>
                  )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
}
