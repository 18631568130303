import { Navbar, Nav, Button, ButtonGroup, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PageHeader } from "./styledComponent";

const routeNamesMapper = {
  "admin.loan": "Loan",
  "admin.savings": "Saving",
  admin: "Dashboard",
  "admin.members": "Members",
  "admin.savings.entry": "Deposit Fund",
  "admin.loan.provide": "Provide Loan",
  "admin.loan.view": "Loan clearance",
  "admin.members.create": "Add Member",
  "admin.members.edit": "Edit Member",
  "admin.members.view": "View Member",
  "admin.savings.edit": "Edit Deposit",
  "admin.savings.view": "View Deposit",
  "admin.savings.withdraw": "Withdraw Fund",
};

function parsePathName(path){
  if (typeof path !== "string" || path === "") return path;
  path = path.split('/').filter(x=>!!x).join('.')
  for (let item of ["edit", "view"]) {
  if (path.includes(item))
    path =path.split(".", path.split(".").indexOf(item) - 1).join(".") +
      `.${item}`;
    }
  return path;
}

function CustomNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [headerText, setHeaderText] = useState("Dashboard");
  useEffect(() => {
    // console.log(location);
    const pathName = parsePathName(location.pathname);
    console.log(pathName);
    if (pathName in routeNamesMapper) {
      setHeaderText(routeNamesMapper[pathName]);
    }
  }, [location]);
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Nav style={{ marginTop: "5px" }} className="me-auto">
          <PageHeader>{headerText}</PageHeader>
          {/* <Form className="d-flex">
            <InputGroup>
              <Button style={{ backgroundColor: "#adadad", border: 0 }}>
                <FontAwesomeIcon icon={faList} />
              </Button>{" "}
              <Button style={{ backgroundColor: "#adadad", border: 0 }}>
                <FontAwesomeIcon icon={faThLarge} />
              </Button>{" "}
              <Button
                style={{
                  backgroundColor: "#fff",
                  color: "#efefef",
                  border: 0,
                }}
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
              <FormControl
                type="text"
                placeholder="Search anything"
                aria-label="Search"
                aria-describedby="btnGroupAddon2"
              />
            </InputGroup>
          </Form> */}
        </Nav>
        {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>

          <ButtonGroup aria-label="Basic example">
            <Button
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                navigate("/");
              }}
              style={{ backgroundColor: "#7613ad", border: 0 }}
            >
              <FontAwesomeIcon icon={faDoorOpen} />
            </Button>
          </ButtonGroup>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
